import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import styles from "./header.module.scss"
import scrollTo from "gatsby-plugin-smoothscroll"
import { MDBHamburgerToggler } from "mdbreact"

import "../../../styles/Navbar.css"
import Winter2022Menu from "../../Winter2022Menu"
import "../../../styles/Winter2022Menu.css"
import { IsMobileDevice } from "../../../utils"

// Images
import logo from "../../../images/sunsets2023/follow_the_sunset.png"
import arrowDown from "../../../images/winter2022/arrowDown.png"
import verticalLine from "../../../images/winter2022/verticalLine.png"
import instagram from "../../../images/winter2022/instaIcon.png"
import facebook from "../../../images/winter2022/facebookIcon.png"
import menuIcon from "../../../images/winter2022/menu_icon.svg"
import closeIcon from "../../../images/winter2022/close_icon.svg"

const Winter2022Header = () => {
  const [isCollapse, setIsCollapse] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(IsMobileDevice())
  }, [])

  useEffect(() => {}, [isCollapse])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.imageContainer}>
        <img src={logo} alt="corona logo" className={styles.logo} onClick={()=>navigate("/")} />
        </div>
      
<div className={styles.buttonsContainer} > <div className={styles.buttons}>
        <button>
          <h3 onClick={() => navigate("/sunsets/worldtour")}> SUNSET WORLDTOUR </h3>
          </button>
          <button>
          <h3 onClick={() => navigate("/sunsets/sessions")}> SUNSET SESSIONS </h3>
          </button>
        </div></div>
       
      </div>
      
      <div className={styles.socials}>
        <a href="https://instagram.com/corona_argentina" target="blank">
          <img src={instagram} alt="arrowDown" className={styles.insta} />
        </a>
        <a href="https://www.facebook.com/cervezacorona" target="blank">
          <img src={facebook} alt="arrowDown" className={styles.facebook} />
        </a>
        <img src={verticalLine} alt="arrowDown" className={styles.line} />
      </div>
    </div>
  )
}

export default Winter2022Header
