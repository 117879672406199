import React, { useEffect, useState } from "react"

import SunsetsContainer from "../../components/Sunsets2023/SunsetsContainer"
import Footer from "../../components/Sunsets2023/WinterFooter"
import SEO from "../../components/seo"

import "../../styles/Global.css"
import "../../styles/layout.module.css"
import AgeGate2 from "../../components/agegate"
import { graphql } from "gatsby"
import AgeGateBoolean from "../../components/AgeGate/AgegateBoolean"

const Sunset2023Page = props => {
  //Aca traemos el listado de todos los hoteles, Cada uno esta dentro de un node
  const [age, setAge] = useState(true)

  useEffect(() => {
    let local = localStorage.getItem("age") || sessionStorage.getItem("age")
    if (local) setAge(false)
  }, [])

  // console.log("props: /// ", props)

  return !age ? (
    <div>
      <SEO title="SUNSETS 2023" />
      <SunsetsContainer
        eventos={props.data.allSunset2023Events.edges}
        backgrounds={props.data.sunset2023Backgrounds.edges}
        location={props.location}
      />
      <Footer />
    </div>
  ) : (
    <AgeGateBoolean location={props.location} setAge={setAge} />
  )
}

export const events = graphql`
  query sunset2023 {
    allSunset2023Events(
      sort: {
        fields: [destacada, index] # Order by destacada first, then by index
        order: [DESC, ASC] # DESC for destacada (true first), ASC for index
      }
    ) {
      edges {
        node {
          visible
          id
          img
          img_mobile
          img_tablet
          index
          cta
          title
          subtitle
          sessionDate
          link
          destacada
          id_url
        }
      }
    }
    sunset2023Backgrounds: allSunset2023Backgrounds {
      edges {
        node {
          name
          img
        }
      }
    }
  }
`

export default Sunset2023Page
