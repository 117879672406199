import React, { useEffect, useState } from "react"
import styles from "./winter2022.module.scss"
import { Header } from "./Header"
import { Promotions } from "./Promotions"
import { Sessions } from "./Sessions"

const Sunset2023Container = ({ eventos, backgrounds, location }) => {
  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [])

  return (
    <>
      {/*<Header />*/}
      <Sessions
        eventos={eventos.filter((elem) => {
          return elem.node.visible
        })}
        backgrounds={backgrounds}
        location={location}
      />
      <Promotions />
    </>
  )
}

export default Sunset2023Container
